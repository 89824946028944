<template>
    <div class="VideoBox" v-if="VideoList.length>0">
        <div class="Video">
            <video ref="myVideo" @play="HandleFullScreenOne" @ended="HandleExitFullScreenOne" :src="VideoList[videoIdx].resourceUrl" style="width:100%;height: 100%;" controls="controls"></video>
        </div>
        <div class="VideoList">
            <div class="summary">
                <div class="Title">视频标题</div>
                <div class="summ">视频简介巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉巴拉</div>
            </div>
            <div class="VideoItem" v-for="(item,index) in VideoList" :key="index" :class="videoIdx==index?'selected':''" @click="changeIdx(index)">
                <div class="Video">
                    <video :src="item.resourceUrl" style="width:100%;height: 100%;"></video>
                </div>
                <div class="Title"><span>{{ item.title }}</span></div>
            </div>
            <div style="color:rgba(255,255,255,.6);;font-size: .875rem;text-align: center;margin-top: 0.6rem;">没有更多视频了...</div>
        </div>
        <!-- <div class="VideoInfo" v-if="showInfo">
            <div>标题：{{ VideoList[videoIdx].title }}</div>
            <div>时间：{{ VideoList[videoIdx].createTime }}</div>
            <div>作者：{{ VideoList[videoIdx].updateBy }}</div>
            <div>简介：{{ VideoList[videoIdx].summary }}</div>
        </div> -->

    </div>
    <div v-else style="height:53vh;align-self: flex-start; margin-left:5vh">
        暂无数据~~~
    </div>
</template>
<script>
import Video from '../../AncientTown/Video.vue'
export default {
    components: { Video },
    props: {
        VideoList: {
            type: Array,
            default: [],
        },
        showInfo: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            videoIdx: 0,
        }
    },
    mounted () {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    },
    methods: {
        // 切换视频
        changeIdx (idx) {
            this.videoIdx = idx
        },
        // 退出全屏暂停播放
        handleFullscreenChange () {
            const video = this.$refs.myVideo;
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause(); // 当视频退出全屏时暂停播放
            }
        },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target);
        },
        // 进入全屏
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                var cssText = "width:100%;height:100%;overflow:hidden;";
                docHtml.style.cssText = cssText;
                docBody.style.cssText = cssText;
                videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
                document.IsFullScreen = true;
            }
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target);
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                docHtml.style.cssText = "";
                docBody.style.cssText = "";
                videobox.style.cssText = "";
                document.IsFullScreen = false;
            }
        },
    },
    beforeUnmount () {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    }
}
</script>
<style lang="less" scoped>
.VideoBox {
    width: 100%;
    // margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    > .Video {
        width: 64%;
        height: 53vh;
        flex-shrink: 0;
        background: black;
    }
    .VideoList {
        background-color: #202020; // rgba(0, 0, 0, 0.68);
        width: 35%;
        height: 53vh;
        overflow-y: scroll;
        margin-left: 1%;
        border-radius: 12px;
        padding: 1rem;
        box-sizing: border-box;
        .summary {
            // margin-bottom: 1rem;
            color: #fff;
            padding: 0 0.5rem 0.5rem;
            line-height: 1.5rem;
            .Title {
                font-size: 1.5rem;
                margin-bottom: 4px;
            }
            .summ {
                font-size: 0.875rem;
                color: #8a8a8a;
            }
        }
        .VideoItem {
            width: 100%;
            display: flex;
            padding: 5px 8px;
            box-sizing: border-box;
            .Video {
                width: 40%;
                video {
                    border-radius: 8px;
                }
            }
            .Title {
                width: 40%;
                margin-left: 12px;
                font-size: 0.875rem;
                color: #fff;
                span {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                }
            }
        }
        .selected .Title {
            color: #08b4b4;
        }
    }
    .VideoInfo {
        width: 100%;
        margin: 1rem 0;
        line-height: 2rem;
    }

    // 滚动条设置
    @remvw: 1920 /100vw;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
</style>